*{
    font-family:'Georgia', 'Times New Roman', 'Times', 'serif',
    
  }
  
  .TitleBar {
    position: relative;
    display: inline-block;
    padding: 10px;
    font-size: 24px; /* Taille du texte */
    white-space: nowrap; /* Empêche le texte de se casser en plusieurs lignes */
  
  }
  
  .TitleBar::before,
  .TitleBar::after {
    content: '';
    position: absolute;
    border-style: solid;
    z-index: 1;
  }
  
  .TitleBar::before {
    border-width: 20px 20px 0 0; /* Bordure plus longue en haut à gauche */
    border-color: transparent var(--couleur-secondaire) var(--couleur-secondaire) transparent;
    top: 40px; /* Ajuste la position si nécessaire */
    left: -8px; /* Ajuste la position si nécessaire */
    transform: scaleX(-1);
  }
  
  .TitleBar::after {
    border-width: 20px 20px 0 0; /* Bordure plus longue en bas à droite */
    border-color: var(--couleur-secondaire) transparent transparent var(--couleur-secondaire);
    bottom: 40px; /*  position  */
    right: -10px; /*  la position  */
    transform: scaleX(-1);
  }
  
  /* Assurez-vous que la navbar n'a pas de débordement */
  .MuiDrawer-paper {
    overflow: hidden; /* Pas de défilement */
  }
  
  .title_navbar{
    color: var(--couleur-secondaire);
    background-color: "transparent";
  }
  .title_navbar::after{
    background-color: inherit;
    border: none;
  }
  /* Responsivité pour les petits écrans */
  @media (max-width: 600px) {
    .TitleBar {
      font-size: 18px; /* Réduit la taille du texte pour les petits écrans */
    }
    .TitleBar::before,
    .TitleBar::after {
      border-width: 15px 15px 0 0; /* Ajuste la taille de la bordure */
    }
   
  }
  