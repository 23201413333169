:root {
  --couleur-secondaire: #27ad6e; /* Couleur primaire */
  --couleur-primaire: #008cba; /* Couleur secondaire */
  --couleur-gradient: linear-gradient(to right, #ff7e5f, #feb47b); /* Gradient */
}
* {
    font-family: 'Georgia', 'Times New Roman', 'Times', 'serif'; 
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }

  body {
    font-family: 'Arial', sans-serif;
  }