/* AnimatedThemeToggleButton.css */
.animated-button {
    position: relative;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 50%; /* Pour un bouton rond */
    padding: 8px; /* Ajustez la taille du bouton selon vos besoins */
  }
  
  .animated-button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Changez ce code pour la couleur de survol */
  }
  
  .animated-button:active {
    transform: scale(0.95); /* Effet de clic */
  }
  
  .animated-button.light {
    color: #000; /* Couleur pour le thème clair */
  }
  
  .animated-button.dark {
    color: #fff; /* Couleur pour le thème sombre */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .animated-button.pulse {
    animation: pulse 1.5s infinite;
  }
  