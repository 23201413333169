.texteintro1{
    color: var(--couleur-secondaire);
     font-weight: "bold";
      margin-top: 2
}
.texteintro2{
    color:var(--couleur-secondaire);
    font-size: 20px;
   font-weight: bold;
}
.texteinto3{
    color: var(--couleur-secondaire);
    margin-top: 15px;
    padding-top: 14px;
    border: 2px;
}
.typewriter span {
    border-right: .15em solid var(--couleur-secondaire); /* Le curseur du typewriter */
    letter-spacing: .15em; /* Ajustez si nécessaire */
    animation:
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  .role{

    color: var(--couleur-secondaire);
    font-Size: 20px;
    font-weight: bold;
  }

  
  /* Effet de frappe */
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  /* Effet de clignotement du curseur */
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: var(--couleur-secondaire); }
  }
  
  /* src/css/Home.css */
  
  /* Responsivité pour les petits écrans */
  @media (max-width: 600px) {
    .cadre {
      padding: 4px; /* Réduit le padding pour les petits écrans */
   
  
    }
    .cadre::before,
    .cadre::after {
      border-width: 0; /* Fait disparaître les bordures pour les petits écrans */
      display: none;
    }
  }
  